import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"

const TidySoftwarePage = () => (

    <Layout pageClass="our-partners-page">
    <SEO title="Experience On Tap - Tidy Software" />
    <main className="main">
        <div className="typography inner">

            <h1>Welcome to Tidy Solutions</h1>
                <div className="paragraph-block">
                    <p><img src="/tidy_charcoal_logo.svg" alt="Tidy logo" width="23%"></img><img src="/tidybuild_square_logo.svg" alt="TidyBuild logo" width="23%" ></img><img src="/tidystock_square_logo.svg" alt="TidyStock logo" width="23%" ></img><img src="/tidywork_square_logo.svg" alt="Tidy logo" width="23%" ></img></p>
                    <p><img src="/tidyenterprise_square_logo.svg" alt="Tidy logo" width="23%" ></img><img src="/tidycommerce_square_logo.svg" alt="Tidy Commerce" width="23%"></img><img src="/tidyclinic_square_logo.svg" alt="TidyClinic logo" width="23%" ></img></p>
                    <p><br />Experience On Tap has expanded. In addition to Advisory services that we have historically provided we are now providing implementation services. Cloud-based software specifically for SMEs:</p>
                     <p><br /><h3>The Tidy product range:</h3>
                        <ul>
                            <li><a href="https://tidyinternational.com/solutions/tidystock" target="_blank" rel="noreferrer">Tidy Stock</a> - buying, selling, and managing inventory.</li>
                            <li><a href="https://tidyinternational.com/solutions/tidywork" target="_blank" rel="noreferrer">Tidy Work</a> - quoting and implementing jobs and projects.</li>
                            <li><a href="https://tidyinternational.com/solutions/tidybuild" target="_blank" rel="noreferrer">Tidy Build</a> - construction management.</li>
                            <li><a href="https://tidyinternational.com/solutions/tidyenterprise" target="_blank" rel="noreferrer">Tidy Enterprise</a> - stock and work plus ecommerce.</li>
                            <li><a href="https://www.tidycommerce.com/" target="_blank" rel="noreferrer">Tidy Commerce</a> - build your own website and sell online.</li>
                            <li><a href="https://www.tidyclinic.com/" target="_blank" rel="noreferrer">Tidy Clinic</a> - the ultimate inventory management for healthcare.</li>
                        </ul>
                    </p>
                   <p><h3>What do we offer:</h3>
                        <ul>
                            <li>To model your key transactions prior to purchase.</li>
                            <li>Implementation - We will set up and test for you.</li>
                            <li>Go-Live - We train and support you on site until you are competent.</li>
                            <li>Ongoing support - We remain your point of contact fully supported by Tidy.</li>
                        </ul>
                    </p>
                   <p><h3>Integrations:</h3>
                        <ul>
                            <li>Xero. Tidy works seamlessly with Xero providing full accounting functionality.</li>
                            <li>CapsuleCRM. Tidy utilises the contact management capabilities of Capsule, storing your important contacts and communications.</li>
                            <li>Dropbox. Providing storage of all your plans, drawings, agreements, contacts, and other documents.</li>
                        </ul>
                    </p>
                   <p><h3>Our expertise:</h3>
                        <ul>
                            <li>Dave Wylie has more than 20 years of experience providing software solutions.</li>
                            <li>Faizal Muhammad has more than 20 years implementing software solutions.</li>
                        </ul>
                    </p>

                   <p><h3>Your result:</h3>
                        <ul>
                            <li>A comprehensive solution appropriate to your business needs and budget.</li>
                            <li>A New Zealand solution used by 1000s of users internationally.</li>
                        </ul>
                    </p>
                </div>  
        </div>
    </main>
    </Layout>

)


export default TidySoftwarePage